package io.kadbase.studio.splashScreen

import io.kadbase.studio.shared.VersionInfo
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.serialization.kotlinx.json.*

/** Api client containing all HTTP requests. */
object ApiClient {

    private const val apiBaseUrl = "/api/v1"

    private val client = HttpClient(Js) {
        install(ContentNegotiation) {
            json()
        }
    }

    /** Returns current product / components versions. */
    suspend fun getVersionInfo(): VersionInfo = client.get("$apiBaseUrl/version-info").body()
}
