package io.kadbase.studio.splashScreen.components

import csstype.ClassName
import csstype.Padding
import csstype.px
import emotion.react.css
import io.kadbase.studio.shared.VersionInfo
import io.kadbase.studio.shared.footerVersionConstant
import io.kadbase.studio.splashScreen.ApiClient
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import react.VFC
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML
import react.useEffectOnce
import react.useState

internal val Footer = VFC {

    var versionInfo by useState(VersionInfo("x.y.z", "x.y.z"))
    useEffectOnce { MainScope().launch { versionInfo = ApiClient.getVersionInfo() } }

    Box {
        className = ClassName("footer")

        Typography {
            variant = TypographyVariant.body2
            ReactHTML.a {
                href = "https://www.iknova.com/produits/kad-base/release-notes-v3-x"
                className = ClassName("footer-text")
                target = AnchorTarget._blank
                +footerVersionConstant(versionInfo)
            }
        }

        ReactHTML.div {
            css { padding = Padding(0.px, 10.px) }
            +"-"
        }

        Typography {
            variant = TypographyVariant.body2
            ReactHTML.a {
                href = "https://knovapedia.kad-office.com/kad/Documentation_Utilisateur_et_Tutoriels_KAD-Base"
                className = ClassName("footer-text")
                target = AnchorTarget._blank
                +"Documentation utilisateur"
            }
        }
    }
}
