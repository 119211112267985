package io.kadbase.studio.shared

import kotlinx.serialization.Serializable

/**
 * Information about product and component versions.
 *
 * @property productVersion Version of KAD-Base product.
 * @property componentVersion Version of KAD-Base Studio component.
 */
@Serializable
data class VersionInfo(val productVersion: String, val componentVersion: String)
