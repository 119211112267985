package io.kadbase.studio.splashScreen

import csstype.ClassName
import io.kadbase.studio.splashScreen.components.Footer
import kotlinx.browser.document
import kotlinx.browser.window
import mui.material.*
import mui.material.styles.TypographyVariant
import react.VFC
import react.create
import react.dom.client.createRoot

internal data class ConnectViewText(val software: String, val connect: String)

/** App component implementing the React Router */
val ConnectApp = VFC {

    val text = ConnectViewText("KAD-Base v3", "Se connecter")

    Box {
        className = ClassName("connectApp-box")

        Box {
            className = ClassName("connectApp-box-connection")

            Typography {
                className = ClassName("connectApp-box-connection-title")
                variant = TypographyVariant.h1
                +text.software
            }

            Button {
                className = ClassName("connectApp-box-connection-button")
                variant = ButtonVariant.contained
                onClick = { window.open("/runner", "_self") }
                +text.connect
            }
        }

        Divider {
            orientation = Orientation.horizontal
            variant = DividerVariant.middle
        }

        +Footer.create()
    }
}

/** Entry point of KAD-Base frontend. */
fun main() = createRoot(document.getElementById("connectRootContainer")!!).render(ConnectApp.create())
